import  {getVitalSigns} from '../services/Api'
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Empty from '../components/Empty'
import Loading from './LoadingIndicator'
function VitalSignRecord(props){
  const { t } = useTranslation();

  const data = props.data
  
    return(
      <div className='record col-3 col-s-12 '>

      
        <div className='vital-sign'>
          <img src={require('../assets/images/'+ props.title +'.svg')} alt="Logo" className='icon-vital'/>
          <div className='info'>
            <div className='vital-title' > {t('vital-signs.' + props.title )} </div>
            <div className='vital-values'> 
              {data.value} {data.units}
            </div>
          </div>
        </div>  
        <style jsx >{` 
          .record{
            padding-left: 0px;
  
          }
          .vital-sign{
            border-radius: 4px;
            border: solid 1px var(--light-sky-blue);
            background-color: var(--white);
            display: inline-flex;
            width: 200px;
            height: 52px;
          }
          .vital-title{
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: var(--steel);
          }
          .vital-values{
            font-size: 24px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: var(--dark-blue-grey);
          }
          img{
            align-self: center;
          }
          .icon-vital{
            width: 50px;
            height: 50px;
          }
          .info{
            padding-left: 16px;
          }
        `}</style>
      </div>
     
    )
   

}

function VitalSigns(props) {
  const [empty, setEmpty] = useState(true);
  const [loading, setLoading] = useState(true);
  const [signs, setSigns] = useState([]);
  const patient = props.patient_id;
  async function  componentDidMount() {

    try {
      const response = await getVitalSigns(patient);
      if(response.request.status === 200){
        const elements = response.data.vital_signs.elements;
        
        if(Object.entries(elements).length !== 0){
          
          setSigns(elements);
          setEmpty(false)
        }
        setLoading(false);
        

      }
    }catch (e) {
      setLoading(false);
      console.warn("ERROR", e);
      
    }
  }

  useEffect(() => {
    componentDidMount();
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      <div className="signs-container">
        <Loading working={loading}/>
        <Empty empty={empty}/>
        { Object.keys(signs).map((item,index) => <VitalSignRecord  key={index} title={item} data={signs[item]}/>)}
        </div>
    </React.Fragment>
  )
}


export default VitalSigns