import React, { useState, useEffect }  from 'react';
import  {getMedicalHistory} from "../services/Api"
import Record from './MedicalHistoryRecord';
import Allergies from './MedicalHistoryAllergies'
import Loading from '../components/LoadingIndicator'
import Empty from './Empty';
function Medicalhistory(props) {
  var patient_id = props.patient?.id;
  const gender = props.patient?.gender;
  const historyConfig = props.patient?.medical_history_config;
  const restrictedItems = new Set(["perinatal_records", "postnatal_records", "gineco_obstetrics_records"]);
  const [loading, setLoading] = useState(true);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [empty, setEmpty] = useState(true);
  async function componentDidMount() {
    try {
      const response = await getMedicalHistory(patient_id);
      setMedicalHistory(response.data.medical_history);
      setLoading(false);
      setEmpty(false)
    }catch (e) {
      setLoading(false);
      console.warn("ERROR", e);
     
    }
  }

  function renderOptions() {
   
    if(!empty){
      return  Object.keys(medicalHistory.records).map((item,index) => {
        const val = medicalHistory.records[item];
        if (val && Object.keys(val).length > 0 && typeof val == 'object'){
          if(item !== 'allergies' && natalHistory(item) && showGroup(item)) {
            return(
              <Record name={item} values={val}  key={index} id= {medicalHistory.id} editable={medicalHistory.editable}/>
            )
          }
        }
       
     })
    }
    else{
      return (<Empty empty={empty}/>)
    }
  }

  function showGroup(group) {
    switch (group) {
      case 'diet':
        return historyConfig['diet'] || false;
      case 'perinatal_records':
        return historyConfig['perinatal'] || false;
      case 'postnatal_records':
        return historyConfig['postnatal'] || false;
      case 'psychiatric_records':
        return historyConfig['psychiatric'] || false;
      case 'vaccines_mx':
        return historyConfig['vaccines_mx'] || false;
      case 'heredofamilial_records':
        return historyConfig['heredo_familial'] || false;
      case 'pathological_records':
        return historyConfig['pathological'] || false;
      case 'nonpathological_records':
        return historyConfig['non_pathological'] || false;
      case 'gineco_obstetrics_records':
        return historyConfig['gineco_obstetrics'] || false;
      default:
        return false;
    }
  }

  function natalHistory(item_name) {
    if (gender !== "f" && restrictedItems.has(item_name)) {
      return false;
    }
    return true;
  }
 
 useEffect(() => {
   componentDidMount();
   // eslint-disable-next-line
 }, []);
 function renderAllergies(){
  if(!empty){
    const val_allergies  = medicalHistory.records['allergies'];
    
    if(val_allergies !== null){
      return(
        <Allergies others={val_allergies}/>
      )
    }
  }
 }
  return (
    <div className="medical-history"> 
      <ul className="medical-history-list">
        <Loading working={loading}/>
        {renderAllergies()}
        {renderOptions()}
      </ul>
      <style jsx='true'>{`
      .medical-history-list{
        width: 80%;
      }
       .forms {             
          margin-left: 60px;
          margin-right: 30px;
          background-color: red;
        }
        .medical-history ul {
          padding-left: 0px;
          width: 50%;
          list-style-type: none;
          margin-top: 44px;
        }
        .medical-history {
          padding-left: 18px;
        }
      `}</style>
    </div>
  );
}
export default Medicalhistory;
