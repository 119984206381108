import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import MedicalHistory from '../components/MedicalHistory';
import { useTranslation } from 'react-i18next';
import withProfileLayout from '../hocs/withProfileLayout';
import MedicalIcon from '../assets/images/medical-history-on.svg'
import MedicalOffIcon from '../assets/images/medical-history-off.svg'
import VitalIcon from '../assets/images/vital-on.svg'
import VitalOffIcon from '../assets/images/vital-off.svg'
import MedicationsIcon from '../assets/images/medications-on.svg'
import MedicationsOffIcon from '../assets/images/medications-off.svg'
import EncounterIcon from '../assets/images/past-encounter-on.svg'
import EncounterOffIcon from '../assets/images/past-encounter-off.svg'
import AttachmentsIcon from '../assets/images/attachment.svg'
import AttachmentsOffIcon from '../assets/images/attachment.svg'
import PrescriptionsIcon from '../assets/images/past-encounter-off.svg'
import PrescriptionsOffIcon from '../assets/images/past-encounter-off.svg'
import VitalSigns from '../components/VitalSigns';
import LabTests from '../components/LabTests';
import Drugs from '../components/ActiveDrugs';
import Attachments from '../components/Attachments';
import Consultations from '../components/Consultations';
import Prescriptions from '../components/Prescriptions';
import  { getOrganizationData } from "../services/Api";

function Profile(props) {
  const [key, setKey] = useState('medical-history');
  const [medical, setMedical] = useState(MedicalIcon);
  const [vital, setVital] = useState(VitalOffIcon);
  const [medications, setMedications] = useState(MedicationsOffIcon);
  const [attachments, setAttachments] = useState(AttachmentsOffIcon);
  const [prescriptions, setPrescriptions] = useState(PrescriptionsOffIcon);
  const [encounters, setEncounter] = useState(EncounterOffIcon);
  const [shareMedicalHistory, setShareMedicalHistory] = useState(false);
  const patient = props.location.state.patient;
  const { t } = useTranslation();


  async function  componentDidMount() {
    try {
      const response = await getOrganizationData(patient.id);
      if(response.status === 200){
        const shareMedical = response.data.public_organization.options.share_medical_history;
        setShareMedicalHistory(shareMedical);
        if(shareMedical){
          setKey('medical-history');
          setMedical(MedicalIcon);
          setAttachments(AttachmentsOffIcon);
        }else{
          setKey('attachments');
          setMedical(MedicalOffIcon);
          setAttachments(AttachmentsIcon);
        }
      }
    }catch (e) {
      setShareMedicalHistory(true);
    }
    return true;
  }

  useEffect(() => {
    componentDidMount();
  }, [])

  function handleTab(e){  
    setKey(e)
    setMedical(MedicalOffIcon)
    setVital(VitalOffIcon)
    setMedications(MedicationsOffIcon)
    setPrescriptions(MedicationsOffIcon)
    setAttachments(AttachmentsOffIcon)
    setEncounter(EncounterOffIcon)
    switch (e){
      
      case 'medical-history':
        setMedical(MedicalIcon)
        break;
      case 'vital-signs':
        setVital(VitalIcon)
        break;
      case 'active-medications':
        setMedications(MedicationsIcon)
        break;
      case 'attachments':
        setAttachments(AttachmentsIcon)
        break;
      case 'prescriptions':
        setPrescriptions(MedicationsIcon)
        break;
      case 'past-encounter':
        setEncounter(EncounterIcon)
        break;
      default:
        setMedical(MedicalIcon)
        break;
    }
  }

  return (
    <div className='profile-container'>
      <h1 className='title-section'> {t('profile.sections.my-health')}</h1>
      <div className='tabs-container'>
        <Tabs defaultActiveKey={key} activeKey={key} id="profile-tab" onSelect={handleTab.bind()}>
         {shareMedicalHistory && <Tab eventKey="medical-history" title={ <span>  <img src={medical} alt="Logo" className='icon'/> {t('medical-history.my-medical-history')} </span>}>
              <div className='jumbotron'>
              <div className='medical-header'> {t('medical-history.history')} </div>
              <MedicalHistory patient={patient} />
            </div>
          </Tab>
        }
          {shareMedicalHistory && <Tab eventKey="vital-signs" title= { <span>  <img src={vital} alt="Logo" className='icon'/>{t('medical-history.my-vital-signs')} </span>} >
            <div className="row"> 
              <div className=' col-9 col-s-12'>
                  <div> {t('vital-signs.title')} </div>
                  <VitalSigns patient_id={patient.id} />
              </div>
              <div className='col-9 col-s-12'>
                  <div className='lab-test-result-header'> {t('lab-test-result.title')} </div>
                  <LabTests patient_id={patient.id} />
              </div>
            </div>
          </Tab>}
          {/* <Tab eventKey="active-medications" title= { <span>  <img src={medications} alt="Logo" className='icon'/> {t('medical-history.my-active-medications')}  </span>} >
            <div>
                <div className='medications-header'> {t('medical-history.history')} </div>
                <Drugs patient_id={patient.id} />
            </div>
          </Tab> */}
          <Tab eventKey="attachments" title= { <span>  <img src={attachments} alt="Logo" className='icon'/> {t('attachments.title')}  </span>} >
            <div className="row"> 
              <div className='col-9 col-s-12'>
                <Attachments patient_id={patient.id} />
              </div>
            </div>
          </Tab>
          {shareMedicalHistory && <Tab eventKey="prescriptions" title= { <span>  <img src={medications} alt="Logo" className='icon'/> {t('prescriptions.title')}  </span>} >
            <div>
              <Prescriptions patient_id={patient.id} />
            </div>
          </Tab>}
          <Tab eventKey="past-encounter" title= { <span>  <img src={encounters} alt="Logo" className='icon'/> {t('medical-history.my-past-encounters')} </span>} >
            <Consultations patient_id={patient.id}/>
          </Tab>
        </Tabs>
      </div>
      <style jsx global >
      {`
        .icon {
          width: 16px;
          height: 14px;
          object-fit: contain;
          margin-right: 5px;
        }
        
        .profile-container .title-section{
          margin: 24px 41px;
          font-size: 28px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);
        }
        .medical-header{
          border: solid 1px var(--light-sky-blue);
          background-color: #f4f7f9;
          padding: 13px 18px;
        }
       
        img {
            width: 150px;
        }
        .profile-container .tab-content{
          background-color: white;
          border: solid 1px var(--light-sky-blue);
          padding-left: 23px;
          padding-right 23px;
        }
        .profile-container .tabs-container{
          margin-left: 23px;
          background-color: white;
          // border: solid 1px var(--light-sky-blue);
          border-radius: 4px;
          border-top: white !important;
          margin-right: 31px;
        }

        .profile-container  .nav-link{
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);
        }
        .nav-tabs{
          background-color:  var(--ice-blue)  !important;
        }
        .jumbotron{
          padding: 0px 0px !important;
          margin-top: 35px;
          background-color: white;
          border-radius: 4px;
          border: solid 1px var(--light-sky-blue);
        }
       
      `}</style>
    </div>
  );
}

export default withProfileLayout(Profile);